/**
 * Site header
 */
 .site-header {
     		border-bottom: 1px solid #fff;
     		min-height: 56px;
        margin-top: 0px;
        margin-bottom: 10px;


    // Positioning context for the mobile navigation icon
    position: relative;
    text-align: center;

}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}


/* hilde's eigen menu */

.menuknop	{
          position: fixed;
          top: 10%;
          left: 10%;
          max-width: 10%;
          }


            .site-nav ul
            						{
            						max-width: 100%;
            						display: block;
            						justify-content: center;
            						margin: 3px;
            						padding: 10px;
                        background-color: #FFFFFF;
            						}


            .site-nav li
            						{
            						text-align: center;
            						font-family: Ubuntu, sans-serif;
            						font-size: 65%;
            						line-height: 0.8;
            						padding: 5px;

            						list-style-type: none;
            						}

            /* flex styles voor grotere schermen*/

            @media screen and (min-width: 768px)
            						{


            .site-nav ul{
            						display: flex;

            						}

            .site-nav li{
            						padding: 0px 15px 0px 15px;
            						}

            						}


/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
    text-align: center;
    clear: right;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */

.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: 14px;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-family: 'Oswald', sans-serif;
    font-size: 215%;
    letter-spacing: -0.5px;
    line-height: 1.25;

    @include media-query($on-laptop) {
        font-size: 215%;
    }
}

.date {
  font-family: 'Lato', sans-serif;
  font-size: 100%;
  line-height: 1.5;
}

.post-content {
    font-family: 'Lato', sans-serif;
    font-size: 108%;
    margin-bottom: $spacing-unit;
    padding: 10px;
    max-width: 100%;

    img {
      max-width: 100%;
    	vertical-align: middle;
	}


  h1 {
      font-family: 'Oswald', sans-serif;
      font-size: 175%;

      @include media-query($on-laptop) {
          font-size: 175%;
      }
  }


    h2 {
        font-family: 'Oswald', sans-serif;
        font-size: 150%;

        @include media-query($on-laptop) {
            font-size: 150%;
        }
    }

    h3 {
        font-family: 'Lato', sans-serif;
        font-size: 100%;
        font-style: italic;
        text-align: left;
        color: grey;

        @include media-query($on-laptop) {
            font-size: 100%;
        }
    }

}



/* wordpress conversie classes */
.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float:right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(/wordpress/css/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url(/wordpress/css/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUZiYySUhiCXAA.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
